<template>
  <el-dialog
    v-model="showDialog"
    :title="$t.translate('TTL_TRANSLATION')"
    custom-class="cus-cont"
  >
    <label for="">EN</label>
    <el-input
      v-model="FormData.EN"
      :rows="3"
      type="textarea"
      style="margin-bottom: 10px"
      placeholder="EN"
      :class="{
        'inp-invalid': $h.isBlank(FormData.EN),
      }"
    />

    <label for="">CHS</label>
    <el-input
      v-model="FormData.CHS"
      :rows="3"
      type="textarea"
      style="margin-bottom: 10px"
      placeholder="CHS"
      :class="{
        'inp-invalid': $h.isBlank(FormData.CHS),
      }"
    />

    <label for="">CHT</label>
    <el-input
      v-model="FormData.CHT"
      :rows="3"
      type="textarea"
      style="margin-bottom: 10px"
      placeholder="CHT"
      :class="{
        'inp-invalid': $h.isBlank(FormData.CHT),
      }"
    />

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showDialog = false">{{
          $t.translate("BTN_CANCEL")
        }}</el-button>
        <el-button type="primary" @click="confirmTranslate">{{
          $t.translate("BTN_SAVE")
        }}</el-button>
      </span>
    </template>
  </el-dialog>

  <div>
    <div class="container-fluid pt-8 pb-5">
      <el-collapse class="expand-main mb-3 shadow">
        <el-collapse-item class="expand-item">
          <template #title>
            <div class="row pl-3">
              <div class="col-12 d-flex align-items-center">
                <i class="fas fa-search"></i>
                <h3 class="mb-0 ml-2">{{ $t.translate("LBL_SEARCH") }}</h3>
              </div>
            </div>
          </template>
          <card shadow type="secondary" class="">
            <div class="">
              <div class="row">
                <div class="col-lg-6">
                  <base-input
                    v-on:keyup.enter="filterAction"
                    v-model="Filter.Search"
                    input-classes="form-control-alternative"
                  />
                </div>
              </div>
              <div class="row mt-1 mb-2">
                <div class="col-lg-6">
                  <el-button type="primary" @click="() => filterAction(1)">
                    {{ $t.translate("BTN_SEARCH") }}
                  </el-button>
                </div>
              </div>
            </div>
          </card>
        </el-collapse-item>
      </el-collapse>

      <div class="shadow bg-white p-around">
        <Table
          url="/translation/list"
          :query="{ ...Filter }"
          ref="filttable"
          :viewData="handleEdit"
          height="58vh"
        >
          <el-table-column
            :label="$t.translate('FRM_TRANSLATION_CODE')"
            prop="TranslationCode"
            min-width="140"
          ></el-table-column>

          <el-table-column label="EN" prop="EN" min-width="140"></el-table-column>
          <el-table-column label="CHS" prop="CHS" min-width="140"></el-table-column>
          <el-table-column label="CHT" prop="CHT" min-width="140"></el-table-column>
        </Table>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "@/components/Table.vue";

import { reactive, onMounted, inject, ref } from "vue";
import { get, post, isValidResponse } from "@/utils/axios";
import { translation } from "@/utils/translation";
import { router } from "@/utils/router";
import { ElMessageBox } from "element-plus";
import { useRoute } from "vue-router";

export default {
  components: { Table },
  setup() {
    let filttable = ref("filttable");

    let showDialog = ref(false);
    let FormData = reactive({
      EN: "",
      CHS: "",
      CHT: "",
      TranslationCode: "",
    });

    let Filter = reactive({
      Search: "",
      Category: "COUNTRY_LIST",
    });

    const handleEdit = async (scope, row) => {
      if (row && row?.className?.indexOf("action-col") > -1) return;
      // router.changeRoute(`/staff/edit/${scope.UserKey}`);

      FormData.EN = scope.EN;
      FormData.CHS = scope.CHS;
      FormData.CHT = scope.CHT;
      FormData.TranslationCode = scope.TranslationCode;

      showDialog.value = true;
    };

    let filterAction = async () => {
      await filttable.value.handleCurrentChange(1, Filter);
    };

    const resetParams = () => {
      for (let key in Filter) {
        Filter[key] = "";
      }
      filterAction();
    };

    const confirmTranslate = async () => {
      let res = await post("/translation/submit", FormData);
      if (isValidResponse(res)) {
        showDialog.value = false;
        await filttable.value.handleCurrentChange();
      }
    };

    return {
      FormData,
      confirmTranslate,
      showDialog,
      Filter,
      filttable,
      resetParams,
      handleEdit,
      filterAction,
    };
  },
};
</script>
<style></style>
