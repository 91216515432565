<template>
  <div class="container-fluid pt-8 pb-5">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card card-body shadow">
          <div class="border-bottom p-2">
            <h3><b>{{ $t.translate("TTL_STRIPE_CONNECT") }}</b></h3>
          </div>

          <div v-if="!PageData.isConnected" class="p-2 pt-4">
            <h5 class="text-gray">{{ $t.translate("LBL_SETUP_STRIPE_ACCOUNT") }}</h5>

            <el-button type="primary" class="mt-3" @click="connectStripe">
              {{ $t.translate("BTN_CONNECT_NOW") }}
            </el-button>
          </div>

          <div v-if="PageData.isConnected" class="p-2 pt-4">
            <h5 class="text-gray">{{ $t.translate("LBL_STRIPE_ACC_CONNECTED") }}</h5>
            <div class="mt-1 d-flex align-items-center">
              <h5 class="text-gray mb-0">{{ $t.translate("LBL_STRIPE_CONN_ACCOUNT") }}:</h5>
            </div>

            <div class="">
              <span class="text-white acc-ref-cont">{{ PageData.account }}</span>
            </div>

            <el-button type="primary" class="mt-4" @click="disconnStripe">
              {{ $t.translate("BTN_DISCONNECT") }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { router } from "@/utils/router";
import { post, get, isValidResponse } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { store } from "@/store";
import { ElMessageBox } from "element-plus";
import { translation } from "@/utils/translation";

export default {
  setup() {
    let PageData = reactive({ isConnected: false, account: null });

    onMounted(async () => {
      await getStripe();
    });

    const getStripe = async () => {
      let res = await get("/stripe/connect", {});
      PageData.isConnected = res?.IsStripeAccountSetuped ? true : false;
      PageData.account =
        res?.StripeId && res?.IsStripeAccountSetuped ? res.StripeId : null;
    };

    const connectStripe = async () => {
      let res = await get("/stripe/connect/link");
      window.location.href = res?.Link;
    };

    const disconnStripe = async () => {
      try {
        await ElMessageBox.confirm(
          translation.translate("MSG_WARNING_CONFIRM_DELETE"),
          translation.translate("TTL_WARNING"),
          {
            confirmButtonText: translation.translate("BTN_OKAY"),
            cancelButtonText: translation.translate("BTN_CANCEL"),
            type: "warning",
          }
        );

        let res = await post("/stripe/disconnect");
        if (isValidResponse(res)) {
          await getStripe();
        }
      } catch (err) {
        //err
      }
    };

    return { PageData, connectStripe, disconnStripe };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.acc-ref-cont {
  background: rgb(245, 108, 108);
  border-radius: 20px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 0.8125rem;
}
</style>
